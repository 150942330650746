import React from 'react';
import { Box, Heading, Flex, Card, Text, Button } from '@radix-ui/themes';
import './css/GettingStarted.css';

const StepCard = ({ number, title, description }) => (
  <Card className="step-card">
    <Box>
      <Text className="step-number">{number}.</Text>
      <Heading size="4" className="step-title">{title}</Heading>
      <Text className="step-description">{description}</Text>
    </Box>
  </Card>
);

const GettingStarted = () => (
  <Box className="getting-started-container">
    <Box className="getting-started-content">
      <Heading align="center" className="main-heading">
        ¡Empezar a utilizarlo es muy fácil!
      </Heading>
      <Flex direction="row" gap="4" justify="between">
        <StepCard 
          number="1" 
          title="Instala nuestro Add On en Google Sheets" 
          description="Una instalación rapida y segura, no más de 2 minutos" 
        />
        <StepCard 
          number="2" 
          title="Vincula tu cuenta de Mercado Libre" 
          description="Una conexión segura y cuya aplicación es aprobada por Mercado Libre." 
        />
        <StepCard 
          number="3" 
          title="Eso es todo! Empieza la recolección." 
          description="Los datos de tus productos, ventas entre otros de Mercado Libre empezaran a obtenerse y guardarse." 
        />
      </Flex>
      <Flex justify="center" style={{marginTop:'3rem'}}>
        <Button size="4" className="get-started-button">
          Descubre más →
        </Button>
      </Flex>
    </Box>
  </Box>
);

export default GettingStarted;