import React from 'react';
import './css/Hero.css';
import template_start from '../../assets/images/templat/hero_image.png';

const Hero = () => (
  <section className="hero">
    <div className="hero__inner">
      <div className="hero__content">
        <h1 className="hero__title">
          Plantillas personalizables gratis
        </h1>
        <p className="hero__description">
          Potencia tus hojas de calculo y tu información con formulas integradas en nuestras plantillas diseñadas especialmente para ti!
        </p>
        <button className="hero__button">
          ¡Obten acceso ahora!
        </button>
      </div>
      <div className="hero__image">
        <img src={template_start} alt="Spreadsheet Templates" />
      </div>
    </div>
  </section>
);

export default Hero;