import React, { useState } from 'react';
import { Container, Heading, Flex, Box, Text, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import YouTube from 'react-youtube';
import Modal from 'react-modal';
import '../../styles/HowItWorks.css'

// Asegúrate de establecer el elemento raíz de la aplicación para el modal
Modal.setAppElement('#root'); // Ajusta esto si tu elemento raíz tiene un id diferente

const Step = ({ number, title, description }) => (
  <Box 
    style={{
      flex: '1 1 250px',
      textAlign: 'center',
      maxWidth: '350px',
      border: '2px solid black', // Marco negro
      backgroundColor: 'white', // Fondo blanco
      borderRadius: '8px',
      padding: '16px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    }}
  >
    <Box
      style={{
        backgroundColor: '#4CAF50',
        color: 'white',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto 16px',
        fontSize: '20px',
        fontWeight: 'bold'
      }}
    >
      {number}
    </Box>
    <Heading className='title-card-howit'>{title}</Heading>
    <Text className='description-card-howit'>{description}</Text>
  </Box>
);

const HowItWorks = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const videoOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <Container size="5" py="9" style={{ backgroundColor: '#f5f5f5' }}>
      <Heading className='title-howit'>
        Empezar a utilizarlo es fácil
      </Heading>
      <Flex justify="center" gap="3" wrap="wrap" style={{ marginBottom: '80px' }}>
        <Step 
          number="1"
          title="Instala el Add On de Google Sheets"
          description="La extensión se encuentra disponible en el Workspace."
        />
        <Step 
          number="2"
          title="Ingresa las credenciales proporcionadas."
          description="Conecta tu cuenta creada en Sheetseller."
        />
        <Step 
          number="3"
          title="Empieza un nuevo mundo de información"
          description="Descubre todas las funciones que tenemos disponibles para ti!"
        />
      </Flex>
      <Box style={{ textAlign: 'center', width: '100%',  maxWidth: '1000px', margin: '0 auto', aspectRatio: '16/9', position: 'relative'}}>
        <div 
          onClick={openModal}
          style={{ 
            cursor: 'pointer',
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius: '8px',
            overflow: 'hidden'
          }}
        >
          <img 
            src="https://img.youtube.com/vi/iighYGd_Ehw/maxresdefault.jpg" 
            alt="Click to play video" 
            style={{ 
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }} 
          />
          <div 
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '68px',
              height: '48px',
              backgroundColor: 'rgba(0,0,0,0.7)',
              borderRadius: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div 
              style={{
                width: '0',
                height: '0',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderLeft: '20px solid white'
              }}
            />
          </div>
        </div>
      </Box>


      <Flex 
        gap="6" 
        align="center" 
        justify="center" 
        style={{ 
          marginTop: '2rem',
          width: '100%'
        }}
      >
        <Button 
          size="4" 
          className="custom-button-how"
        >
          Empieza ahora
          <CursorArrowIcon style={{ marginLeft: '8px' }} />
        </Button>
        <Button 
          size="4" 
          variant="outline"
          className="outline-button-how"
        >
          Probar Demo
        </Button>
      </Flex>


      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            background: 'none',
            width: '90%', // Cambiado a porcentaje
            aspectRatio: '16/9', // Mantiene la proporción
            maxWidth: '1200px',
            overflow: 'hidden'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 1000,
            overflow: 'hidden'
          }
        }}
      >
        <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
          <YouTube videoId="iighYGd_Ehw" opts={videoOpts} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}/>
        </div>
      </Modal>

      

    </Container>
  );
};

export default HowItWorks;