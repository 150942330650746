import React from 'react';
import { Box, Heading, Text, Flex, Button } from '@radix-ui/themes';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { FaChartLine, FaChartBar, FaCoins } from 'react-icons/fa';
import './css/GameChanger.css';

const data = [
  { name: 'Ene', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 600 },
  { name: 'Abr', value: 800 },
  { name: 'May', value: 500 }
];

const features = [
  { icon: <FaChartLine />, text: "Métricas Calculadas" },
  { icon: <FaChartBar />, text: "Datos en Tiempo Real" },
  { icon: <FaCoins />, text: "ROI Optimizado" }
];

const GameChanger = () => {
  return (
    <Box className="game-changer-container">
      <Flex className="game-changer-content">
        {/* Columna de gráficos */}
        <Box className="charts-column">
          <Box className="tablet-frame">
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Line type="monotone" dataKey="value" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        {/* Columna de texto */}
        <Box className="text-column">
          <Heading className="main-title-changer">
            Un Cambio de Juego Para Tu Negocio
          </Heading>
          <Text className="description-changer">
            Conecta tu cuenta de vendedor y mira cómo se despliegan los datos.
            Optimiza tus operaciones con análisis en tiempo real.
          </Text>
          <Box className="features-list-changer">
            {features.map((feature, index) => (
              <Flex key={index} className="feature-item">
                <Box className="feature-icon">{feature.icon}</Box>
                <Text>{feature.text}</Text>
              </Flex>
            ))}
          </Box>
          <Button size="4" className="access-button-changer">
            Obtener Acceso AHORA →
          </Button>
        </Box>

      </Flex>
    </Box>
  );
};

export default GameChanger;