import React from 'react';
import { Box, Heading, Text, Flex, Button } from '@radix-ui/themes';
import { FaChartPie, FaSearchDollar, FaBoxes } from 'react-icons/fa';
import './css/PowerfulInsights.css';

const features = [
  { icon: <FaChartPie />, text: "Ganancias de un Vistazo" },
  { icon: <FaSearchDollar />, text: "Identifica Pérdidas Ocultas" },
  { icon: <FaBoxes />, text: "Gestión de Inventario" }
];

const PowerfulInsights = () => {
  return (
    <Box className="insights-container">
      <Flex className="insights-content">
        {/* Columna de visualización */}
        <Box className="visualization-column">
          <Box className="floating-elements">
            {/* Elementos flotantes decorativos */}
            <Box className="float-item chart"></Box>
            <Box className="float-item box"></Box>
            <Box className="float-item coin"></Box>
          </Box>
        </Box>
        {/* Columna de texto */}
        <Box className="text-column">
          <Heading size="8" className="main-title">
            Insights Poderosos
          </Heading>
          <Text size="4" className="description">
            Obtén los detalles de tu rendimiento y análisis en profundidad.
            Toma decisiones informadas con datos precisos y actualizados.
          </Text>
          <Box className="features-list">
            {features.map((feature, index) => (
              <Flex key={index} className="feature-item">
                <Box className="feature-icon">{feature.icon}</Box>
                <Text>{feature.text}</Text>
              </Flex>
            ))}
          </Box>
          <Button size="4" className="insights-button-changer">
            Ver Análisis AHORA →
          </Button>
        </Box>

      </Flex>
    </Box>
  );
};

export default PowerfulInsights;