import React from 'react';
import styled from 'styled-components';
import { Container, Heading } from '@radix-ui/themes';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { keyframes } from "styled-components";

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
    opacity: 1;
    transform: translateY(0);
  }
  to {
    height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }
`;


const StyledAccordion = styled(AccordionPrimitive.Root)`
  width: 100%;
`;

const StyledItem = styled(AccordionPrimitive.Item)`
  margin-top: 1px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledHeader = styled(AccordionPrimitive.Header)`
  all: unset;
  display: flex;
`;

const StyledTrigger = styled(AccordionPrimitive.Trigger)`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.777rem;
  line-height: 1;
  color: #333;
  cursor: pointer;
  position: relative; // Añadido para la barra indicadora

  &:hover {
    background-color: #dcdcdc;
  }

  & > svg {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }

  &[data-state='open']::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #4CAF50; // Color verde que coincide con tu diseño
    transition: opacity 300ms ease;
  }
`;

const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  font-size: 1.333rem;
  color: #333;
  background-color: #f5f5f5;
  padding: 15px 20px;
  
  // Contenedor interno para el padding
  > div {
    padding: 15px 20px;
  }

  // Animación al abrir
  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  // Animación al cerrar
  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  // Estilos adicionales para mejorar la apariencia
  line-height: 1.5;
  will-change: height;
  transition: background-color 200ms ease;

  // Hover effect sutil
  &:hover {
    background-color: #f5f5f5;
  }

  // Asegura que el contenido se mantenga legible
  p {
    margin: 0;
    color: #666;
  }

  // Ajuste para dispositivos móviles
  @media (max-width: 768px) {
    font-size: 13px;
    
    > div {
      padding: 12px 16px;
    }
  }
`;

const FAQContainer = styled(Container)`
  padding: 4rem 0;
  background-color: #f7f7f7; // Cambia el fondo del contenedor a un suave gris claro
`;

const AccordionItem = ({ value, question, answer }) => (
  <StyledItem value={value}>
    <StyledHeader>
      <StyledTrigger>
        {question}
        <ChevronDownIcon />
      </StyledTrigger>
    </StyledHeader>
    <StyledContent>{answer}</StyledContent>
  </StyledItem>
);

const PricingFAQ = () => (
  <FAQContainer size="3">
    <Heading align="center" style={{ marginBottom: '5rem', color: '#111', fontSize: '2.369rem'}}>
      Preguntas Frecuentes
    </Heading>
    <StyledAccordion type="single" collapsible>
      <AccordionItem
        value="item-1"
        question="¿Cómo funciona la prueba gratuita?"
        answer="Ofrecemos una prueba gratuita de15 días para que puedas experimentar todas las características de SheetSellerApp sin compromiso."
      />
      <AccordionItem
        value="item-2"
        question="¿Puedo cambiar de plan en cualquier momento?"
        answer="Sí, puedes actualizar tu plan en cualquier momento. Los cambios se reflejarán en tu próxima factura."
      />
      <AccordionItem
        value="item-3"
        question="¿Qué métodos de pago aceptan?"
        answer="Aceptamos tarjetas de crédito/débito y PayPal para todos nuestros planes."
      />
      <AccordionItem
        value="item-4"
        question="¿Es seguro ingresar datos bancarios?"
        answer="Si, por supuesto. A la hora de pagar una suscripción, el formulario de datos bancarios es proveido por Mercado Pago, 
        los cuales se encargan de la seguridad de esos datos completamente. Nosotros no alamecenamos sus datos bancarios en nuestras bases de datos."
      />
      <AccordionItem
        value="item-5"
        question="¿Puedo suscribirme sin tener alguna cuenta de Mercado Pago.?"
        answer="Claro, si bien Mercado Pago es la entidad que se encarga de los datos bancarios, no es necesario el uso de una cuenta, solo tendra que
        ingresar un correo electronico en donde le llegaran los cobros mensuales."
      />
      <AccordionItem
        value="item-6"
        question="Si no tengo una cuenta Mercado Pago, ¿ Como cancelo la suscripción?"
        answer="En nuestra plataforma, en el apartado del perfil del usuario, existe un opción para cancelar la suscripción, el cual se comunica con 
        Mercado Pago y cancela el plan."
      />
    </StyledAccordion>
  </FAQContainer>
);

export default PricingFAQ;