import ReviewsHero from "../components/reviews/ReviewsHero";
import { Box, Container } from '@radix-ui/themes';
import TrustedCompanies from '../components/common/TrustedCompanies'
import ReviewsCards from "../components/reviews/ReviewsCards";

const Reviews = () => {
    return (
      <div>
        <ReviewsHero />
        <Box py="4" style={{ backgroundColor: 'white' }}>
          <Container>
            <TrustedCompanies />
          </Container>
        </Box>
        <ReviewsCards />
      </div>
    );
};


export default Reviews;