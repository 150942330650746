import React from 'react';
import styled from 'styled-components';
import { Container, Heading, Text, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import './css/PricingHero.css'

const HeroContainer = styled(Container)`
  background: linear-gradient(45deg, #1E293B, #2D3748);
  color: white;
  padding: 4rem 0;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;

const PricingHero = () => (
  <HeroContainer size="5">
    <Heading style={{ marginBottom: '2rem', fontSize: '4.209rem', lineHeight: '4rem'}}>
      Libérate de la entrada manual de datos con precios flexibles
    </Heading>
    <Text style={{ marginBottom: '3rem', fontSize: '2.369rem'}}>
      Planes para vendedores de Mercado Libre de todos los tamaños. Realiza tu prueba de 15 días.
    </Text>
    <ButtonContainer>
      <Button size="3" className='custom-button-Pricinghero'>
        Ver planes y precios
        <CursorArrowIcon style={{ marginLeft: '8px' }} />
      </Button>
    </ButtonContainer>
  </HeroContainer>
);

export default PricingHero;