import React, { useState, useEffect } from 'react';
import { Container, Heading, Flex, Box, Text } from '@radix-ui/themes';
import { FaStar, FaQuoteLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  background-color: #ffffff;
  padding: 4rem 0;
`;

const ReviewsHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  gap: 1rem;
`;

const NavigationButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #02A724;
  }
`;

const TestimonialCard = ({ content, author, role, avatar, highlightedText }) => {
  const renderHighlightedContent = (text, highlight) => {
    if (!highlight) return text;
    
    const parts = text.split(highlight);
    return (
      <>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i < parts.length - 1 && (
              <span style={{ backgroundColor: '#FFF9C4' }}>{highlight}</span>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between' // Esto ayudará a alinear el footer
      }}
    >
      <Box>
        <FaQuoteLeft size={24} color="#02A724" style={{ marginBottom: '1rem' }} />
        <Text style={{ marginBottom: '2rem', lineHeight: '1.6', flex: 1, fontSize: '1rem'}}>
          {renderHighlightedContent(content, highlightedText)}
        </Text>
      </Box>
      <Flex align="center" gap="3" style={{ marginTop: 'auto' }}> {/* Esto mantendrá el footer alineado */}
        {avatar ? (
          <img 
            src={avatar} 
            alt={author} 
            style={{ 
              width: '48px', 
              height: '48px', 
              borderRadius: '50%',
              objectFit: 'cover'
            }} 
          />
        ) : (
          <Box 
            style={{ 
              width: '48px', 
              height: '48px', 
              borderRadius: '50%',
              backgroundColor: '#E0E0E0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '20px',
              color: '#666'
            }}
          >
            {author.charAt(0)}
          </Box>
        )}
        <Box>
          <Text style={{ fontWeight: 'bold', fontSize: '1rem'}}>{author} <br /></Text>
          <Text style={{ color: '#666', fontSize: '0.75rem'}}>{role}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

const testimonials = [
  {
    content: "SheetSellerApp es la única herramienta que proporciona los datos directamente para que puedas trabajar con ellos como quieras, en lugar de reportes pre-programados.",
    author: "María González",
    role: "Vendedor en Mercado Libre",
    highlightedText: "la única herramienta que proporciona los datos directamente"
  },
  {
    content: "La extracción manual de datos solía consumir mucho tiempo. SheetSellerApp ha reducido significativamente el tiempo que dedico a la gestión de datos.",
    author: "Juan Pérez",
    role: "Vendedor en Mercado Libre",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    highlightedText: "reducido significativamente"
  },
  {
    content: "He probado varios software para gestionar mis números, pero siempre los encontraba limitados. Hasta que encontré SheetSellerApp. Ahora puedo diseñar las hojas de cálculo como quiero.",
    author: "Ana Torres",
    role: "Vendedor en Mercado Libre",
    highlightedText: "siempre los encontraba limitados"
  },
  {
    content: "La flexibilidad de SheetSellerApp es incomparable. Puedo personalizar mis reportes exactamente como necesito para mi negocio.",
    author: "Roberto Sánchez",
    role: "Vendedor en Mercado Libre",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
    highlightedText: "flexibilidad"
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <StyledContainer>
      <ReviewsHeader>
        <Flex gap="1">
          {[...Array(5)].map((_, i) => (
            <FaStar key={i} color="#FFD700" size={24} />
          ))}
        </Flex>
        <Heading style={{ margin: '0 1rem', fontSize: '1.777rem'}}>
          Google Marketplace Reviews
        </Heading>
        <Flex gap="2">
          <NavigationButton onClick={handlePrev}>
            <FaChevronLeft />
          </NavigationButton>
          <NavigationButton onClick={handleNext}>
            <FaChevronRight />
          </NavigationButton>
        </Flex>
      </ReviewsHeader>

      <Box style={{ position: 'relative', overflow: 'hidden' }}>
        <Flex 
          style={{
            display: 'flex',
            gap: '2rem',
            padding: '0 1rem',
            transition: 'transform 0.5s ease-in-out', // Asegúrate de que solo sea transform
            transform: `translateX(-${(currentIndex % testimonials.length) * (100/3)}%)` // Ajustado el transform
          }}
        >
          {[...testimonials, ...testimonials].map((testimonial, index) => ( // Duplica los testimonios para un desplazamiento infinito sin espacios en blanco
            <Box
              key={`testimonial-${index}`}
              style={{
                flex: '0 0 calc(33.333% - 1.33rem)',
                minWidth: 'calc(33.333% - 1.33rem)',
                transition: 'opacity 0.5s ease-in-out' // La opacidad como transición es opcional
              }}
            >
              <TestimonialCard {...testimonial} />
            </Box>
          ))}
        </Flex>
      </Box>
    </StyledContainer>
  );
};

export default Testimonials;