import React from 'react';
import Hero from '../components/common/Hero';
import Features from '../components/common/Features';
import Testimonials from '../components/common/Testimonials';
import HowItWorks from '../components/common/HowItWorks';
import FAQ from '../components/common/FAQ';
import OldWay from '../components/common/OldWay';
import NewWay from '../components/common/NewWay';
import WhatToExpect from '../components/common/WhatToExpect';

const LandingPage = () => {
  return (
    <div>
      <Hero />
      <OldWay />
      <NewWay />
      <HowItWorks />
      <Features />
      <Testimonials />
      <WhatToExpect />
      <FAQ />
    </div>
  );
};

export default LandingPage;