// KeyFeatures.jsx
import React from 'react';
import { Box, Heading, Text, Button, Flex, Container } from '@radix-ui/themes';
import { FaDatabase, FaChartBar, FaBoxes, FaChartLine } from 'react-icons/fa';
import './css/KeyFeatures.css';

const defaultFeatures = {
  title: "Características y Beneficios Principales",
  subtitle: "Extracción de Datos",
  description: "Mantente al día con actualizaciones continuas de datos. Visualiza instantáneamente nuevos envíos, ajustes y cambios en el inventario.",
  features: [
    { icon: <FaDatabase />, text: "Datos Automatizados" },
    { icon: <FaChartBar />, text: "Organización Sin Esfuerzo" },
    { icon: <FaBoxes />, text: "Desglose por SKU" },
    { icon: <FaChartLine />, text: "Optimización de Beneficios" }
  ]
};

const KeyFeatures = ({ features = defaultFeatures, image }) => {
  return (
    <Box className="key-features-container">
      <Container size="4">
        <Heading align="center" className="main-title">
          {features.title}
        </Heading>
        
        <Flex className="features-content" align="center" gap="6">
          {/* Columna de imagen */}
          <Box className="features-image">
            <img 
              src={image || '/path-to-default-image.png'} 
              alt="Dashboard visualization" 
              className="dashboard-image"
            />
          </Box>

          {/* Columna de texto */}
          <Box className="features-text">
            <Heading className="subtitle">
              {features.subtitle}
            </Heading>
            <Text className="description">
              {features.description}
            </Text>
            
            <Flex wrap="wrap" gap="4" className="features-grid">
              {features.features.map((feature, index) => (
                <Flex key={index} align="center" gap="2" className="feature-item">
                  <Box className="feature-icon">
                    {feature.icon}
                  </Box>
                  <Text>{feature.text}</Text>
                </Flex>
              ))}
            </Flex>

            <Button className="access-button-key">
              Obtener Acceso HOY →
            </Button>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default KeyFeatures;