import React from 'react';
import styled from 'styled-components';
import { Container, Heading, Flex, Text, Button } from '@radix-ui/themes';
import './css/PricingPlan.css';

const PlansContainer = styled(Container)`
  padding: 4rem 0;
  background-color: #1c1c1c;
`;



const Plan = ({ name, price, features }) => (
  <div className="plan-card">
    <Heading style={{ color: '#fff', fontSize: '1.777rem'}}>{name}</Heading>
    <Text style={{ margin: '1rem 0', color: '#fff', fontSize: '1.777rem'}}>${price}/mes</Text>
    <Button className="plan-button" size="3">
      Elegir plan
    </Button>
    <ul style={{fontSize: '1rem'}}>
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
  </div>
);

const PricingPlans = () => (
  <PlansContainer size="4">
    <Heading align="center" style={{ marginBottom: '5rem', color: '#fff', fontSize: '3.157rem'}}>
      Elige el plan que se adapte a tus necesidades
    </Heading>
    <Flex justify="center" gap="6" wrap="wrap">
      <Plan
        name="Principante"
        price={699}
        features={[
          "Hasta 500 ordenes por mes"
        ]}
      />
      <Plan
        name="Básico"
        price={999}
        features={[
          "Hasta 1000 ordenes por mes",
        ]}
      />
      <Plan
        name="Pro"
        price={1999}
        features={[
          "Hasta 6000 ordenes por mes",
        ]}
      />
      <Plan
        name="Mega"
        price={3999}
        features={[
          "Hasta 15000 ordenes por mes",
        ]}
      />
      <Plan
        name="Enterprise 30k"
        price={5999}
        features={[
          "Hasta 30000 ordenes por mes",
        ]}
      />
      <Plan
        name="Enterprice 50k"
        price={8999}
        features={[
          "Hasta 50000 ordenes por mes",
        ]}
      />
      <Plan
        name="Enterprice 70k"
        price={10999}
        features={[
          "Hasta 70000 ordenes por mes",
        ]}
      />
      <Plan
        name="Enterprice 100k"
        price={14999}
        features={[
          "Hasta 100000 ordenes por mes",
        ]}
      />
    </Flex>
  </PlansContainer>
);

export default PricingPlans;