import * as React from 'react';
import { Container, Heading, Box, Text, Flex } from '@radix-ui/themes';


const ExperienceAverage = () => (
  <Container size="6" py="9" style={{
    backgroundColor: 'white',
    color: 'white'
  }}>
    <Box style={{
      background: 'linear-gradient(135deg, #5D69BE, #C89FEB)',
      borderRadius: '12px',
      padding: '4rem',
      marginTop: '3rem',
      color: 'white',
      textAlign: 'center'
    }}>
      <Flex direction="column" align="center">
        <Text size="7" style={{ marginBottom: '0.5rem', color: '#FFD700' }}>
          ★★★★★
        </Text>
        <Heading style={{ marginBottom: '2rem', fontSize:'3.157rem', lineHeight:'4rem' }}>
            Experimenta por qué nuestros usuarios promedio vende $3 millones al año en Mercado Libre.
        </Heading>
        <Text style={{fontSize:'1.777rem'}}>
            Estás en buena compañía con vendedores sofisticados y basados en datos.
        </Text>
      </Flex>
    </Box>
  </Container>
  );


export default ExperienceAverage;