import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Heading, Text, Flex, Button, Box } from '@radix-ui/themes';
import './css/PricingCalculator.css'

const CalculatorContainer = styled(Container)`
  background-color: #f5f5f5;
  padding: 6rem 0;
`;

const SliderContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 3rem 0;
`;

const Slider = styled.input`
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background: #ddd;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #02A724;
    cursor: pointer;
    transition: background .15s ease-in-out;
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    border: 0;
    border-radius: 50%;
    background: #02A724;
    cursor: pointer;
  }

  &::-webkit-slider-thumb:hover {
    background: #02A724;
  }
`;

const ResultBox = styled.div`
  background: #fff;
  padding: 4rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  margin-top: 3rem;
  width: 100%;
  max-width: 700px;
`;

const PricingCalculator = () => {
  const [orders, setOrders] = useState(500);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [plan, setPlan] = useState('');

  const calculatePrice = (orderCount) => {
    let price;
    let newPlan = '';

    if (orderCount > 70000) {
      price = 14999;
      newPlan = 'Enterprise 100k';
    } else if (orderCount > 50000) {
      price = 10999;
      newPlan = 'Enterprise 70k';
    } else if (orderCount > 30000) {
      price = 8999;
      newPlan = 'Enterprise 50k';
    } else if (orderCount > 15000) {
      price = 5999;
      newPlan = 'Enterprise 30k';
    } else if (orderCount > 6000) {
      price = 3999;
      newPlan = 'Mega';
    } else if (orderCount > 1000) {
      price = 1999;
      newPlan = 'Pro';
    } else if (orderCount > 500) {
      price = 999;
      newPlan = 'Básico';
    } else {
      price = 699;
      newPlan = 'Principiante';
    }

    setEstimatedPrice(price);
    setPlan(newPlan);
  };

  // Calcula el precio inicial y cuando cambia el valor del slider
  useEffect(() => {
    calculatePrice(orders);
  }, [orders]);

  return (
    <CalculatorContainer size="3">
      <Heading align="center" style={{ marginBottom: '3rem', fontSize: '3.157rem'}}> 
        Estima tu precio mensual
      </Heading>
      <Flex direction="column" align="center" gap="5">
        <SliderContainer>
          <Text size="5" style={{ marginBottom: '1.5rem', fontSize: '1.777rem'}}>
            Número de órdenes al mes: {orders}
          </Text>
          <Slider
            type="range"
            min="100"
            max="100000"
            value={orders}
            onChange={(e) => setOrders(Number(e.target.value))}
          />
        </SliderContainer>
        
        <ResultBox>
          <Box style={{ marginBottom: '2rem' }}>
            <Text style={{ color: '#02A724', fontWeight: 'bold', fontSize:'2.369rem'}}>
              ${estimatedPrice} 
            </Text>
            <Text size="4" style={{ color: '#666', fontSize:'1.777rem'}}>
              por mes
            </Text>
          </Box>
          
          <Box style={{ marginBottom: '2rem' }}>
            <Text style={{ color: '#333', fontSize:'1.333rem'}}>
              Plan Recomendado: {plan}
            </Text>
          </Box>
          
          <Box>
            <Text style={{ color: '#999', fontSize: '1rem'}}>
              Todas las cifras son estimadas. Los valores reales pueden variar.
            </Text>
          </Box>
        </ResultBox>

        <Button 
          size="4"
          className='custom-button-PricingCal'
        >
          Ir al Checkout →
        </Button>
      </Flex>
    </CalculatorContainer>
  );
};

export default PricingCalculator;