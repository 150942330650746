import React from 'react';
import { Box, Container, Text, Flex } from '@radix-ui/themes';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import ExperienceAverage from '../common/ExperienceAverage';
import SyncBanner from '../templates/SyncBanner';

const ReviewCard = styled(Box)`
  background: #F8F9FA;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  min-width: 330px;
  text-align: center;
  & > div:first-child {
    flex: 1; // El contenedor del contenido principal se expandirá
    display: flex;
    flex-direction: column;
    min-height: 200px; // Altura mínima para el contenido
  }

  & > div:last-child {
    margin-top: auto; // Empuja el footer al fondo
  }
`;

const HighlightedText = styled.span`
  background-color: #FFF9C4;
  padding: 0 2px;
`;

const StarsContainer = styled(Flex)`
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const ReviewGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 3rem 0;
  margin-top: 3rem;
`;

// Estilos para el texto
const ReviewText = styled(Text)`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: center;
`;

const AuthorContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const AuthorImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
`;

const AuthorInitials = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #666;
`;

const AuthorName = styled(Text)`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

// Etiqueta más grande
const AuthorLabel = styled(Text)`
  font-size: 1rem;
  color: #666;
`;

const SmallReview = ({ text, author, highlightedPart }) => {
  const renderText = (text, highlight) => {
    if (!highlight) return text;
    const parts = text.split(highlight);
    return (
      <>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i < parts.length - 1 && <HighlightedText>{highlight}</HighlightedText>}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <ReviewCard>
      <Box>
        <StarsContainer>
          {[...Array(5)].map((_, i) => (
            <FaStar key={i} color="#FFD700" size={20} />
          ))}
        </StarsContainer>
        <ReviewText>
          {renderText(text, highlightedPart)}
        </ReviewText>
      </Box>
      <AuthorContainer>
        {author.avatar ? (
          <AuthorImage src={author.avatar} alt={author.name} />
        ) : (
          <AuthorInitials>
            {author.name.charAt(0)}
          </AuthorInitials>
        )}
        <Box>
          <AuthorName>{author.name} <br /></AuthorName>
          <AuthorLabel>Google Marketplace Review</AuthorLabel>
        </Box>
      </AuthorContainer>
    </ReviewCard>
  );
};

const ReviewsCards = () => {
  const reviews = [
    {
        text: "Great product that integrates with Google Sheets. I can get all the data that I need to put together reports and information in one spot. Easy to use and I love it.",
        author: { name: "Steven Lam" },
        highlightedPart: "Easy to use and I love it"
    },
    {
        text: "ion in one spot. Easy to use and I love it.",
        author: { name: "Steven Lam" },
        highlightedPart: "Easy to use and I love it"
    },
    {
        text: "reat product that integrates with Google Sheets. I can get all the da",
        author: { name: "Teven Lam" },
        highlightedPart: "Easy to use and I love it"
    },
    {
        text: "Great et all the data that I need to put together reports and information in one spot. Easy to use and I love it.",
        author: { name: "Steven Lam" },
        highlightedPart: "Easy to use and I love it"
    },
    // ... Añade más reviews aquí
  ];

  return (
    <>
      <Box style={{ backgroundColor: 'white' }}>
        {/* Primera sección de reviews */}
        <Box style={{ padding: '1rem 0' }}>
          <Container size="3">
            <ReviewGrid>
              {reviews.slice(0, 1).map((review, index) => (
                <SmallReview key={`first-${index}`} {...review} />
              ))}
            </ReviewGrid>
          </Container>
        </Box>

        {/* Componente ExperienceAverage en su propio contenedor */}
        <Box style={{ padding: '1rem 0' }}>
          <ExperienceAverage />
        </Box>

        {/* Segunda sección de reviews en su propio contenedor */}
        <Box style={{ padding: '1rem 0' }}>
          <Container size="3">
            <ReviewGrid>
              {reviews.slice(1, 2).map((review, index) => (
                <SmallReview key={`second-${index}`} {...review} />
              ))}
            </ReviewGrid>
          </Container>
        </Box>

        {/* Componente SyncSales en su propio contenedor */}
        <Box style={{ padding: '1rem 0' }}>
          <SyncBanner />
        </Box>

        {/* Tercera sección de reviews en su propio contenedor */}
        <Box style={{ padding: '1rem 0' }}>
          <Container size="3">
            <ReviewGrid>
              {reviews.slice(2).map((review, index) => (
                <SmallReview key={`third-${index}`} {...review} />
              ))}
            </ReviewGrid>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default ReviewsCards;