import * as React from 'react';
import { Container, Heading, Grid, Box, Text, Flex } from '@radix-ui/themes';
import '../../styles/Features.css'

const Feature = ({ title, description, icon }) => (
  <Box style={{
    backgroundColor: '#2D3748',
    padding: '2rem',
    borderRadius: '8px',
    color: 'white'
  }}>
    {icon}
    <Heading style={{ marginBottom: '1rem', fontSize:'1.777rem'}}>{title}</Heading>
    <Text style={{fontSize:'1.333rem'}}>{description}</Text>
  </Box>
);

const SyncBanner = () => (
  <Box style={{
    background: 'linear-gradient(135deg, #50D5B7, #067D68)',
    borderRadius: '12px',
    padding: '4rem',
    marginTop: '3rem',
    color: 'white',
    textAlign: 'center'
  }}>
    <Flex direction="column" align="center">
      <Text size="7" style={{ marginBottom: '0.5rem', color: '#FFD700' }}> {/* Color dorado para las estrellas */}
        ★★★★★
      </Text>
      <Heading style={{ marginBottom: '2rem', fontSize:'3.157rem', lineHeight:'3.5rem'}}>
        Sincronizamos más de $373M de ventas en Mercado Libre
      </Heading>
      <Text style={{fontSize:'1.777rem'}}>
        Empresas de todo tipo de tamaños confian en nuestra forma de obtener los datos mediante nuestro Add On.
      </Text>
    </Flex>
  </Box>
);

const Features = () => (
  <Container size="6" py="9" style={{
    backgroundColor: '#1E293B',
    color: 'white'
  }}>
    <Heading className='title-features'>
      Por Qué Elegirnos
    </Heading>
    <Grid columns="2" gap="6">
      <Feature 
        icon={<i className="fas fa-chart-line" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>}
        title="Datos en tiempo real" 
        description="Sincroniza tus datos de Mercado Libre automáticamente para obtener información actualizada."
      />
      <Feature 
        icon={<i className="fas fa-file-alt" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>}
        title="Informes personalizables" 
        description="Crea informes a medida para analizar tu rendimiento de ventas."
      />
      <Feature 
        icon={<i className="fas fa-box" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>}
        title="Gestión de inventario" 
        description="Manten monitoreado tu inventario en Mercado Libre."
      />
      <Feature 
        icon={<i className="fas fa-calculator" style={{ fontSize: '2rem', marginBottom: '1rem' }}></i>}
        title="Análisis financiero" 
        description="Rastrea tus ganancias y pérdidas mensuales con facilidad."
      />
    </Grid>
    <SyncBanner />
  </Container>
);

export default Features;