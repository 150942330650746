import {Text, Flex, Box, Grid } from '@radix-ui/themes'
import amazonLogo from '../../assets/images/logos/logo1.png';
import meliLogo from '../../assets/images/logos/logo2.png';
import interpolLogo from '../../assets/images/logos/logo3.png';
import autenticosLogo from '../../assets/images/logos/logo4.png';
import ebaylogo from '../../assets/images/logos/logo5.png';
import '../../styles/TrustedCompanies.css'

const companies = [
  { name: 'Amazon', logo: amazonLogo },
  { name: 'Mercado Libre', logo: meliLogo },
  { name: 'Interpol', logo: interpolLogo },
  { name: 'Autenticos', logo: autenticosLogo },
  { name: 'EBay', logo: ebaylogo },
  { name: 'VAHDAM', logo: 'https://placehold.co/100x50?text=VAHDAM' },
  { name: 'WILDHORN', logo: 'https://placehold.co/100x50?text=WILDHORN' },
  { name: 'ENCASED', logo: 'https://placehold.co/100x50?text=ENCASED' },
  { name: 'RIDGELINE', logo: 'https://placehold.co/100x50?text=RIDGELINE' },
  { name: 'HYPERZON', logo: 'https://placehold.co/100x50?text=HYPERZON' },
];

const TrustedCompanies = () => (
  <Flex direction="column" align="center" gap="7" style={{marginTop:'5rem'}}>
    <Text className='title-trusted'>
      4.7 Rating, 12k+ Downloads, Trusted by Sellers
    </Text>
    <Grid columns="5" gap="6" width="100%">
      {companies.map((company) => (
        <Box key={company.name} style={{ opacity: 0.7 }}>
          <img 
            src={company.logo} 
            alt={`${company.name} logo`} 
            style={{ width: '100%', height: 'auto', maxWidth: '200px' }} 
          />
        </Box>
      ))}
    </Grid>
  </Flex>
);

export default TrustedCompanies;