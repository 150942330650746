import * as React from 'react';
import { Container, Heading, Text, Button, Flex, Box, Grid } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import TrustedCompanies from './TrustedCompanies';
import backgroundImage from '../../assets/images/hero_image.png';
import '../../styles/Hero.css'

const Hero = () => (
  <Box>
    <Box
      style={{
        backgroundColor: '#0A2540', // Color de fondo oscuro
        minHeight: '600px', // Altura ajustada
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Container size="5" py="6">
        <Grid columns="2" gap="6" align="center">
          <Flex direction="column" align="flex-start">
            <Text  style={{ color: '#4CAF50', marginBottom: '1rem', fontSize:'1.777rem'}}>
              Conecta Mercado Libre a Google Sheets
            </Text>
            <Heading className='landing-hero-title'>
              Desbloquea el Poder de tus Datos de Mercado Libre
            </Heading>
            <Text className='landing-hero-description'>
              Conecta Mercado Libre y Google Sheets para mantener tus datos sincronizados en tus hojas de cálculo 24/7.
            </Text>
            <Flex gap="6">
              <Button size="4" className="custom-button-hero">
                Cómo Funciona
                <CursorArrowIcon style={{ marginLeft: '8px' }} />
              </Button>
              <Button size="4" variant="outline" className="outline-button-hero">
                Probar Demo
              </Button>
            </Flex>
          </Flex>
          <Box>
            {/* Aquí puedes colocar la imagen de Google Sheets y Seller Data */}
            <div style={{ width: '100%', height: '300px', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`}}>
              {/* Placeholder para tu imagen */}
            </div>
          </Box>
        </Grid>
      </Container>
    </Box>
    <Box py="4" style={{ backgroundColor: 'white' }}>
      <Container>
        <TrustedCompanies />
      </Container>
    </Box>
  </Box>
);

export default Hero;