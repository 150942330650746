// VisualStorytelling.jsx
import React from 'react';
import { Container, Box, Heading, Text, Flex, Button } from '@radix-ui/themes';
import { FaChartLine, FaDatabase, FaClock } from 'react-icons/fa';
import './css/VisualStorytelling.css';

const defaultStoryData = {
  title: "Visualización de Datos",
  description: "Comprende instantáneamente información crítica como inventario disponible, velocidad de ventas, margen de beneficio actual y tarifas clave, todo mostrado de manera clara en un dashboard integral.",
  features: [
    { icon: <FaChartLine />, text: "Vista General Simplificada" },
    { icon: <FaDatabase />, text: "Datos Organizados" },
    { icon: <FaClock />, text: "Actualizaciones en Tiempo Real" }
  ]
};

const VisualStorytelling = ({ storyData = defaultStoryData, image }) => {
  return (
    <Box className="visual-storytelling-container">
      <Container size="4">
        <Flex className="storytelling-content" align="center" gap="6">
          {/* Columna de texto */}
          <Box className="storytelling-text">
            <Heading className="visual-title">
              {storyData.title}
            </Heading>
            <Text className="visual-description">
              {storyData.description}
            </Text>
            
            <Flex direction="column" gap="4" className="features-list-key">
              {storyData.features.map((feature, index) => (
                <Flex key={index} align="center" gap="3" className="feature-item">
                  <Box className="feature-icon">
                    {feature.icon}
                  </Box>
                  <Text>{feature.text}</Text>
                </Flex>
              ))}
            </Flex>

            <Button className="access-button-storyv">
              Obtener Acceso HOY →
            </Button>
          </Box>

          {/* Columna de imagen */}
          <Box className="storytelling-image">
            <img 
              src={image || '/path-to-default-image.png'} 
              alt="Dashboard visualization" 
              className="dashboard-image"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default VisualStorytelling;