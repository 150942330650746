import React from 'react';
import { useNavigate } from 'react-router-dom';
import template_start from '../../assets/images/templat/template_start.png';
import './css/TemplatesGrid.css';

const TemplateCard = ({ title, description, slug }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/template/${slug}`);
  };

  return (
    <div className="template-card">
      <div className="template-card-content">
        <h3 className="template-card-title">{title}</h3>
        <p className="template-card-description">{description}</p>
        <div className="template-card-button-container">
          <button 
            className="template-card-button"
            onClick={handleClick}
          >
            Descubre más
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplatesGrid = () => (
  <div className="templates-grid-container">
    <div className="templates-grid-content">
      <h2 className="templates-grid-heading">Explora algunas de nuestras plantillas</h2>
      <div className="templatesgrid-image-container">
        <img src={template_start} alt="Ejemplo de plantilla" className="templatesgrid-image" />
      </div>
      <div className="templates-grid">
        <TemplateCard title="Ventas e inventario" description="Obtén tu inventario y ventas actuales." slug="ventas-inventario" />
        <TemplateCard title="Productos y costos" description="Analiza los productos y su costo de venta." slug="productos-costos" />
        <TemplateCard title="Productos y precios" description="Obtén tus productos con su precio de venta." slug="productos-precios" />
        <TemplateCard title="Detalles de tus productos" description="Visualiza tus productos con algunos detalles en específico." slug="detalles-productos" />
        <TemplateCard title="Retiros" description="Visualiza tus retiros junto con los productos que se incluyen." slug="retiros" />
        <TemplateCard title="Ventas" description="Analiza tus ventas actuales y algunos detalles extra." slug="ventas" />
        <TemplateCard title="Competencia" description="Mantén el análisis del estado de tus publicaciones catálogo." slug="competencia" />
        <TemplateCard title="Devoluciones" description="Obtén las devoluciones de tus productos." slug="devoluciones" />
      </div>
    </div>
  </div>
);

export default TemplatesGrid;