import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, Flex, Container } from '@radix-ui/themes';
import { FaDatabase, FaChartBar, FaBoxes, FaChartLine, FaClock } from 'react-icons/fa';
import ventas_inventario from '../../assets/images/templat/templates_images/ventas-inventario.png'
import GettingStarted from './GettingStarted';
import GameChanger from './GameChanger';
import TrustedCompanies from '../common/TrustedCompanies'
import SyncBanner from './SyncBanner';
import KeyFeatures from './KeyFeatures';
import VisualStorytelling from './VisualStorytelling'
import './css/TemplatePage.css';

const templateData = {
  'ventas-inventario': {
    title: 'Ventas e inventario',
    description: 'Obtén tu inventario y ventas actuales de manera detallada y precisa.',
    image: ventas_inventario,
    features: {
      title: "Características y Beneficios Principales",
      subtitle: "Control de Inventario",
      description: "Mantén un seguimiento detallado de tus ventas e inventario en tiempo real.",
      features: [
        { icon: <FaDatabase />, text: "Datos en Tiempo Real" },
        { icon: <FaChartBar />, text: "Análisis de Ventas" },
        { icon: <FaBoxes />, text: "Control de Stock" },
        { icon: <FaChartLine />, text: "Predicciones de Inventario" }
      ]
    },
    storytelling: {
      title: "Visualización de Datos",
      description: "Visualiza tus datos de ventas e inventario de manera clara y efectiva. Toma decisiones informadas con información actualizada en tiempo real.",
      features: [
        { icon: <FaChartLine />, text: "Vista General Simplificada" },
        { icon: <FaDatabase />, text: "Datos Organizados" },
        { icon: <FaClock />, text: "Actualizaciones en Tiempo Real" }
      ]
    }
  },
  'productos-costos': {
    title: 'Productos y costos',
    description: 'Analiza los productos y su costo de venta.',
    image: ventas_inventario
  },
  'productos-precios': {
    title: 'Productos y precios',
    description: "Obtén tus productos con su precio de venta.",
    image:ventas_inventario
  },
  'detalles-productos': {
    title: 'Detalles de tus productos',
    description: 'Visualiza tus productos con algunos detalles en específico.',
    image: ventas_inventario
  },
  'retiros': {
    title: 'Retiros',
    description: "Visualiza tus retiros junto con los productos que se incluyen.",
    image: ventas_inventario
  },
  'ventas': {
    title: 'Ventas',
    description: 'Analiza tus ventas actuales y algunos detalles extra.',
    image: ventas_inventario
  },
  'competencia': {
    title: 'Competencia',
    description: 'Mantén el análisis del estado de tus publicaciones catálogo.',
    image: ventas_inventario
  },
  'devoluciones': {
    title: 'Devoluciones',
    description: 'Obtén las devoluciones de tus productos.',
    image: ventas_inventario
  }
};

const TemplatePage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const template = templateData[slug];

  if (!template) {
    return <div>Plantilla no encontrada</div>;
  }

  return (
    <Box className="template-page-wrapper">
      <Container size="3" className="template-page">
        <Flex direction="column" align="center" gap="6">
          <Box className="template-content" text-align="center">
            <Text className="template-subtitle">Plantillas gratuitas de Sheetseller</Text>
            <Heading className="template-title">{template.title}</Heading>
            <Text className="template-description">{template.description}</Text>
          </Box>
          
          <Box className="template-image-container">
            <img src={template.image} alt={template.title} className="template-hero-image" />
          </Box>
          
          <Button size="4" className="template-start-button" onClick={() => navigate('/start')}>
            Get Started Today
          </Button>
        </Flex>
      </Container>
      
      <Box className="additional-components"  style={{color: 'black'}}>
        <Box py="4" style={{ backgroundColor: 'white' , color: 'black'}}>
          <Container>
            <TrustedCompanies />
          </Container>
        </Box>
        <GettingStarted />
        <KeyFeatures features={template.features} image={template.image} />
        <SyncBanner />
        <VisualStorytelling storyData={template.storytelling} image={template.image} />
        <GameChanger />
      </Box>
    </Box>
  );
};

export default TemplatePage;